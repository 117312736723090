body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*, :after, :before, html {
  box-sizing:border-box
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
	text-decoration: none;
	color: inherit;
}

li {
  margin-bottom: 10px;
}

.hoverBox:hover .hoverBoxColorStrip {
  display: block;
}

::selection {
  background: #FFF8E4; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: #FFF8E4; /* Gecko Browsers */
}

@font-face {
  font-family: "Karla-Regular";
  font-weight: 400;
  src: local("Karla-Regular"),
    url(./fonts/Karla-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Karla-Bold";
  font-weight: 600;
  src: local("Karla-Bold"), url(./fonts/Karla-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "PTMono-Regular";
  font-weight: 500;
  src: local("PTMono-Regular"),
    url(./fonts/PTMono-Regular.ttf) format("truetype");
}
@font-face {
  font-family: 'Tiempos-Headline';
  src: local('Tiempos-Headline'),
    url(./fonts/TiemposHeadline-Black.otf) format('opentype');
}
@font-face {
  font-family: 'Tiempos-Text-Semibold';
  src: local('Tiempos-Text-Semibold'),
    url(./fonts/TiemposHeadline-Semibold.otf) format('opentype');
}
